import React from "react";
import { useNavigate } from "react-router-dom";

export default function HeroSection() {
    const navigate = useNavigate();
    const handleSignUpClick = () => {
        navigate("/Instruction"); // Navigate to the sign-up page
      };

    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
<br/>
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">The most fun</span>{" "}
              <br />
              way to learn Golf ever.
            </h1>
            <p className="hero--section-description">
              At Birdie we take the stress out of golf
              <br/> Sign up today to have more fun on the course.
            </p>
          </div>
          <button onClick={handleSignUpClick} className="btn btn-primary">Get Started</button>
        </div>
        <div className="hero--section--img">
          <img src="https://golfgolfappassets.s3.amazonaws.com/Login+Screen+BG+High+Res.mov" alt="Hero Section" />
        </div>
      </section>
    );
  }