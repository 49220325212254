import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateY(0);
  overflow-x: hidden;
  overflow-y: auto;
`;

const ProgressBar = styled.div`
  height: 50px;  // Increased height
  width: 80%;
  background-color: #ccc;
  border-radius: 35px;  // Rounded corners
  margin: auto;  // Centering it
`;

const ProgressFiller = styled.div`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: #6C63FF;
  border-radius: 35px;  // Same as ProgressBar
  transition: width 0.5s ease-in-out;
  min-width: 15px;  // Ensures rounded corners are visible
`;


const HomeButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #6C63FF;
  border: none;
  cursor: pointer;
`;

const Circle = styled.div`
background-color: #6C63FF;
width: 200px;
height: 200px;
border-radius: 50%;
position: absolute;
top: -120px;
right: -120px;
display: flex;
justify-content: flex-start;
align-items: flex-end;
color: white;
font-size: 20px;
padding-left: 30px;
padding-bottom: 55px;
font-weight: bold;
`;

const Image = styled.img`
width: 70%;
margin: 90px auto;
display: block;

@media (min-width: 390px) and (max-width: 414px) {
  width: 80%;
}

@media (min-width: 415px) and (max-width: 896px) {
  width: 90%;
}
`;

export default function Loading() {
  const [progress, setProgress] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          clearInterval(interval);
          navigate('/Form'); // Replace with the actual form path
          return 100;
        }
        return oldProgress + 1;
      });
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <HomeButton onClick={() => navigate('/')}>
        <Circle>Home</Circle>
      </HomeButton>
      <Image src="https://golfgolfappassets.s3.amazonaws.com/undraw_golf_neir.svg" alt="Golf" />
      <ProgressBar>
        <ProgressFiller progress={progress} />
      </ProgressBar>
    </Container>
  );
}
