import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import ReactPlayer from 'react-player';

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right 0;
    bottom: 0;
    background: linear-gradient(
        180deg, 
        rgba(0,0,0,0.2) 0%, 
        rgba(0,0,0,0.5) 100%
        ), 
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 0;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  --o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index: 1;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;
  
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const FormInputContainer = styled.div`
  position: relative;
  margin-bottom: 32px;
`;

export const FormInput = styled.input`
  padding: 16px 38px;
  padding-right: 100px;
  border: none;
  border-radius: 50px;
  border: 2px solid #01bf71;
  font-size: 16px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const FormInput2 = styled.input`
  padding: 16px 38px;
  padding-right: 100px;
  border: none;
  border-radius: 50px;
  border: 2px solid #01bf71;
  font-size: 16px;
  width: 100%;
  text-align: center;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #fff;
  }

  /* Vendor Prefixes for better cross-browser support */
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
  }
`;


export const SendButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #01bf71;
  border: none;
  border-radius: 50px;
  color: white;
  padding: 10px 16px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #000;
    color: #01bf71;
  }
`;
