  const paymentReducer = (state = false, action) => {
    switch (action.type) {
      case "PAYMENT_SUCCESS":
        return true;
      case "RESET":
        return false;
      default:
        return state;
    }
  }

  export default paymentReducer;