import { put, takeLatest, call } from 'redux-saga/effects';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { createEmails } from '../../../graphql/mutations';  // import the GraphQL mutations you've defined


function* createEmail(action) {
  try {
    

    

    const result = yield call(
      [API, API.graphql],
      graphqlOperation(createEmails, { input: action.payload })
    );

    // Assuming you have an action creator `emailCreated` for updating your Redux store
    yield put({ type: 'EMAIL_CREATED', payload: result.data.createEmail, });
        console.log(result)
  } catch (error) {
    // Handle error
    console.error('Error creating email:', error);
    yield put({ type: 'EMAIL_CREATION_FAILED', error });
  }
}

export default function* watchCreateEmail() {
  yield takeLatest('NEW_USER', createEmail);
}
