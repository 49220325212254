import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateY(0);
  overflow-x: hidden;
  overflow-y: auto;
`;



const HomeButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #6C63FF;
  border: none;
  cursor: pointer;
`;

const Circle = styled.div`
  background-color: #6C63FF;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: -120px;
  right: -120px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: white;
  font-size: 20px;
  padding-left: 30px;
  padding-bottom: 55px;
  font-weight: bold;
`;

const Image = styled.img`
  width: 70%;
  margin: 90px auto;
  display: block;

  @media (min-width: 390px) and (max-width: 414px) {
    width: 80%;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    width: 90%;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  margin-top: 70px;

  @media (min-width: 390px) and (max-width: 414px) {
    font-size: 1.5em;
    margin-top: 20px;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    font-size: 1.8em;
    margin-top: 15px;
  }
`;

const Subtitle = styled.p`
  font-weight: lighter;
  margin-bottom: 40px;

  @media (min-width: 390px) and (max-width: 414px) {
    font-size: 1.2em;
    margin-top: 20px
  }

  @media (min-width: 415px) and (max-width: 896px) {
    font-size: 1.3em;
    margin-bottom: 15px;
  }
`;

const NextButton = styled.button`
  background-color: #6C63FF;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  width: 300px;
  border: none;
  cursor: pointer;
  margin-top: 50px;

  @media (min-width: 390px) and (max-width: 414px) {
    padding: 18px 35px;
    margin-top: 100px
  }

  @media (min-width: 415px) and (max-width: 896px) {
    padding: 20px 40px;
  }
`;

const FormElement = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin: 10px;
  padding: 10px;
  border-radius: 20px;  // Rounded corners
  border: 1px solid #ccc;  // Light grey border
  width: 80%;  // Width set to 80%
  font-size: 1em;  // Font size
  &:focus {
    border-color: #6C63FF;  // Focus border color
    box-shadow: 0 0 5px #6C63FF;  // Focus box shadow
  }
`;
export default function Form() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const [full_name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [course, setCourse] = useState('');
    const [level, setLevel] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (!full_name || !email || !course || !level) {
        alert('All fields must be filled out.');
        return;
      }

      const payload = { full_name, email, course, level };
      dispatch({ type: 'NEW_USER', payload });
      navigate('/Results');
    };
  
    return (
      <Container>
        <HomeButton onClick={() => navigate('/')}>
          <Circle>Home</Circle>
        </HomeButton>
        <Title>Get your results</Title>
        <Subtitle>Please fill in the credentials to get your results</Subtitle>
        <FormElement onSubmit={handleSubmit}>
          <Input type="text" placeholder="Full Name" value={full_name} onChange={(e) => setFullName(e.target.value)} />
          <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <Input type="text" placeholder="Course" value={course} onChange={(e) => setCourse(e.target.value)} />
          <Input type="text" placeholder="Level" value={level} onChange={(e) => setLevel(e.target.value)} />
          <NextButton type="submit">Get Results</NextButton>
        </FormElement>
      </Container>
    );
  }