import React from "react";
import Header from "../Components/Header/Header";
import SwingForm from "../Components/SwingForm/SwingForm";
import Results from "../Components/Results";
import { useSelector } from "react-redux";

const Construct = () => {
    const results = useSelector(state => state.resultsreducer);

    return (
        <>
        <center>
        <div style={{marginTop: '20px'}}>
        {results ? <Results/> : <SwingForm/>}
        </div>
        </center>
        </>
    )
}

export default Construct;

