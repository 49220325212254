import AboutMe from "../AboutMe";
import Competition from "../Competition";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import MySkills from "../MySkills";
import Testimonial from "../Testimonials";
import TrustedBy from "../TrustedBy";

export default function Home() {
  return (
    <>
      <HeroSection />
      {/* <TrustedBy/> */}
      <MySkills />
      <AboutMe />
      <Footer />
      
    </>
  );
}