import { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  const handleSignUpClick = () => {
    navigate("/Instruction"); // Navigate to the sign-up page
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu(); // Corrected: Added parentheses to call the function
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu(); // Corrected: Added parentheses to call the function
    }
  }, []);

  return (
    <nav className={`navbar ${navActive ? 'active' : ''}`}>
      <div>
      <img
            src="https://golfgolfappassets.s3.amazonaws.com/Birdie.png"
            alt="Logoipsum"
            style={{ height: '50px', width: '50px', marginBottom: 0, marginRight: -15, marginTop: 5, zIndex: 1000 }}
          />
      </div>
      <div // Corrected: Replaced <a> with <div> and added role and tabIndex
        className={`nav__hamburger ${navActive ? 'active' : ''}`}
        onClick={toggleNav}
        role="button"
        tabIndex={0}
        onKeyPress={toggleNav} // Added for keyboard accessibility
      >
        <span className="nav__hamburger__line" />
        <span className="nav__hamburger__line" />
        <span className="nav__hamburger__line" />
      </div>
      <div className={`navbar--items ${navActive ? 'active' : ''}`}>
        <ul>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy
              smooth
              offset={-70}
              duration={500}
              to="heroSection"
              className="navbar--content"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy
              smooth
              offset={-70}
              duration={500}
              to="mySkills"
              className="navbar--content"
            >
              Instructions
            </Link>
          </li>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy
              smooth
              offset={-70}
              duration={500}
              to="AboutMe"
              className="navbar--content"
            >
              About Us
            </Link>
          </li>
        </ul>
      </div>
      <Link
        onClick={handleSignUpClick}
        activeClass="navbar--active-content"
        spy
        smooth
        offset={-70}
        duration={500}
        to="Contact"
        className="btn btn-outline-primary"
      >
        Try It
      </Link>
    </nav>
  );
}

export default Navbar;
