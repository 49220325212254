import { Link } from 'react-scroll';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

function Footer() {
  const navigate = useNavigate();

  const handlePrivacyClick = () => {
    window.open('/privacyPolicy', '_blank'); // Open Privacy Policy in a new tab
  };

  const handleTermsClick = () => {
    window.open('/TermsOfUse', '_blank'); // Open Terms of Use in a new tab
  };

  return (
    <footer className="footer--container">
      <div className="footer--link--container">
        <div>
        <img
            src="https://golfgolfappassets.s3.amazonaws.com/Birdie.png"
            alt="Logoipsum"
            style={{ height: '50px', width: '50px', marginBottom: 0, marginRight: -15, marginTop: 5, zIndex: 1000 }}
          />
        </div>
        <div className="footer--items">
          <ul>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy
                smooth
                offset={-70}
                duration={500}
                to="heroSection"
                className="text-md"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy
                smooth
                offset={-70}
                duration={500}
                to="mySkills"
                className="text-md"
              >
                Instructions
              </Link>
            </li>
            <li>
              <Link
                activeClass="navbar--active-content"
                spy
                smooth
                offset={-70}
                duration={500}
                to="AboutMe"
                className="text-md"
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer--social--icon">
          <ul>
            <li>
              <a href="https://www.instagram.com/getbirdieapp/" className="navbar--content" target="_blank" rel="noreferrer">
                <InstagramIcon /> {/* Alternative for Instagram */}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="divider" />
      <div className="footer--content--container">
        <p className="footer--content">Birdie</p>
        <div className="footer--social--icon">
        </div>
      </div>
    </footer>
  );
}

export default Footer;
