const resultsreducer = (state = null, action) => {
    switch (action.type) {
      case "SET_RESULTS":
        console.log(action.payload)
        return action.payload.content;
      case "RESET":
        return null;
      default:
        return state;
    }
  }

  export default resultsreducer;