import React from "react";
// Make sure the casing matches the actual file name.
import { ModalOverlay, ModalContainer, CloseButton } from './ModalElements';


function Modal({ open, description, closeModal }) {

  
    return (
      <ModalOverlay>
        <ModalContainer>
          <CloseButton onClick={closeModal}>
            
          </CloseButton>
        </ModalContainer>
      </ModalOverlay>
    );
  }
  
  export default Modal;