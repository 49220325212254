/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmails = /* GraphQL */ `
  mutation CreateEmails(
    $input: CreateEmailsInput!
    $condition: ModelEmailsConditionInput
  ) {
    createEmails(input: $input, condition: $condition) {
      id
      email
      full_name
      course
      level
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEmails = /* GraphQL */ `
  mutation UpdateEmails(
    $input: UpdateEmailsInput!
    $condition: ModelEmailsConditionInput
  ) {
    updateEmails(input: $input, condition: $condition) {
      id
      email
      full_name
      course
      level
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEmails = /* GraphQL */ `
  mutation DeleteEmails(
    $input: DeleteEmailsInput!
    $condition: ModelEmailsConditionInput
  ) {
    deleteEmails(input: $input, condition: $condition) {
      id
      email
      full_name
      course
      level
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
