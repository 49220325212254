import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SideBar from "./components/Sidebar";
import Home from "./pages";
import ProtectedRoute from "./ProtectedRoute";
import Contact from "./pages/contact";
import SignInPage from "./components/SignIn";
import Construct from "./app/Construct/Construct";
import SignUpPage from "./components/SignUp";
import FullReport from "./app/Components/FullReport/fullReport";
import Instruction from "./app/Pages/Instruction/Instruction";
import Upload from "./app/Pages/Upload/Upload";
import Loading from "./app/Pages/Loading/Loading";
import Form from "./app/Pages/ResultsForm/Form";
import Results from "./app/Components/Results";
import HomePage from "./landingPage/HomePage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/report" element={<FullReport />} />
        <Route path="/Instruction" element={<Instruction />} />
        <Route path="/Upload" element={<Upload />} />
        <Route path="/Loading" element={<Loading />} />
        <Route path="/Form" element={<Form />} />
        <Route path="/Results" element={<Results />} />
      </Routes>
    </Router>
  );
}

export default App;
