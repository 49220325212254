const initialState = {
    file: null,
    error: null,
    data: null,
    uploading: false,
};

const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIATE_UPLOAD':
            return {
                ...state,
                file: action.payload,
                uploading: true
            };
        case 'UPLOAD_SUCCESS':
            return {
                ...state,
                data: action.payload,
                uploading: false
            };
        case 'UPLOAD_FAILURE':
            return {
                ...state,
                error: action.payload,
                uploading: false
            };
        default:
            return state;
    }
};

export default uploadReducer;
