import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Auth } from "aws-amplify";
import { initiateUpload } from "../../Redux/actions/actions";
import axios from "axios";
import { API } from "aws-amplify";
import FormData from "form-data";
import {
  Button,
  TextField,
  Paper,
  Typography,
  Modal,
  Box,
  CircularProgress,
} from "@mui/material";

export default function SwingForm() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]); // holds the file names
  const [fileContents, setFileContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [clientReferenceId, setClientReferenceId] = useState(null);
  const [image, setImage] = useState(null);
  const [swingText, setSwingText] = useState("");
  const instructionImgUrl = "https://golfgolfappassets.s3.amazonaws.com/Screen+Shot+2023-08-21+at+6.16.06+PM.png"
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false); // Close the modal
    handlePaymentComplete(); // Handle the successful payment
  };

  const handlePaymentComplete = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userEmail = user.attributes.email;

    dispatch({
      type: "GET_ANALYSIS",
      payload: {
        purchaseType: "Premium Swing Analysis",
        userEmail: userEmail,
        photo: fileContents[0],
      },
    });
  };

  const clearSubmit = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setImage(file);
        setFiles([file.name]); // Directly set single file name
      }

      if (fileRejections.length > 0) {
        console.error("Files rejected:", fileRejections);
        // Handle file rejections here, perhaps show an error message to the user.
      }
    },
  });

  function testApi() {
    const formData = new FormData();
    if (files.length > 0) {
      const file = image;
      console.log("Sending file:", file.name, "Size:", file.size);
      formData.append("image", image, "notimage.jpeg");
    }

    const apiUrl =
      "https://3y53ngr856.execute-api.us-east-1.amazonaws.com/glfglfdev/images";
    const testApi = "http://localhost:3000/images";
    fetch(apiUrl, {
      method: "POST",
      body: formData, // No need to manually set 'Content-Type' header
    })
      .then((response) => response.json())
      .then((data) => {
        
        dispatch({
          type: "SET_RESULTS",
          payload: data.externalApiResponse.combined_comment,
        });
        dispatch({
          type: "SET_SCORE",
          payload: data.externalApiResponse.average_score,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    testApi();
  };

  return (
    <div>
      <h1 style={{ color: "#01bf71" }}>Instructions</h1>
      <h4 style={{ color: "#01bf71" }}>Pose just like the photo below and let ai do the rest of the work!</h4>
      <img src={instructionImgUrl} alt="Pose Example" style={styles.image} />
      <Box component="form" sx={styles.formContainer} autoComplete="off">
        <h1 style={{ color: "#01bf71" }}>Upload Swing</h1>
        <Paper elevation={0} {...getRootProps()} sx={styles.dropzoneContainer}>
          <input {...getInputProps()} />
          <p>Drop Swing pic:</p>
          <ul>
            {files.map((file, i) => (
              <li key={i}>{file}</li>
            ))}
          </ul>
        </Paper>

        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={loading}
          style={styles.submitButton}
        >
          {!loading ? (
            "Submit"
          ) : (
            <Box style={{ width: "55px", height: "24px", textAlign: "center" }}>
              <CircularProgress size={15} style={{ marginTop: 5 }} />
            </Box>
          )}
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="stripe-pricing-modal-title"
        aria-describedby="stripe-pricing-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <stripe-pricing-table
            pricing-table-id="prctbl_1Nfat6F0nGJEE7ZqBJkj2bOH"
            publishable-key="pk_live_51NfZ1rF0nGJEE7ZqHnGZ4l2mzSMsXKrUf8Q08tUxv6gsb0deb5nld2tj61ADjtKtIWlNFonvZfYOplIelzxNwV0P00y0k4zlqb"
          ></stripe-pricing-table>
        </Box>
      </Modal>
    </div>
  );
}

const styles = {
  formContainer: {
    marginTop: 5,
    borderRadius: "16px",
    maxWidth: "830px",
    height: "400px",
  },
  mobileFormContainer: {
    maxWidth: "300px",
    height: "500px",
    border: 0,
  },
  dropzoneContainer: {
    p: 2,
    mt: 1,
    mb: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "580px",
    height: "200px",
    listStyleType: "none",
    border: "2px dashed",
    borderColor: "divider",
    "@media (max-width:400px)": {
      marginBottom: 5,
      marginTop: 5,
      maxWidth: "300px",
      height: "300px",
      listStyleType: "none",
    },
  },
  mobileDropzoneContainer: {
    marginBottom: 5,
    marginTop: 5,
    maxWidth: "300px",
    height: "300px",
    listStyleType: "none",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
  },
  submitButton: {
    marginRight: 20,
    backgroundColor: "#01bf71",
    marginBottom: 20
  },
  image:{
    marginTop: 10,
    height: 200,
    width: 140
  }
};
