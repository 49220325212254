import React, { useState } from 'react'
import LandingPage from '.'





export default function HomePage() {
  
  const [isOpen, setIsOpen ] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
    <LandingPage/>
    </>
  )
}
