import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateY(0);
  overflow-x: hidden;
  overflow-y: auto;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const ButtonStyled = styled.button`
  background-color: #6C63FF;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  width: 220px;
  align-self: center;
`;

const Text = styled.div`
  margin: 20px;
  font-size: 15px;
  whiteSpace: 'pre-line';
  marginTop: '30px';
  height: '380px';
  overflowY: 'auto';
  width: '300px';
  textAlign: 'left';
  color: '#8E8E93';
`;


const CircularProgress = ({ radius, stroke, targetProgress }) => {
  const [progress, setProgress] = useState(0);
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < targetProgress) {
          return prevProgress + 1;
        }
        clearInterval(intervalId);
        return prevProgress;
      });
    }, 20);

    return () => clearInterval(intervalId);
  }, [targetProgress]);

  const strokeDashoffset = circumference - progress / 100 * circumference;
  const color = progress > 85 ? 'green' : progress >= 65 ? 'gold' : 'red';

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke="gray"
        fill="transparent"
        strokeWidth={stroke}
        strokeOpacity="0.3"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        strokeLinecap="round"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        transform={`rotate(90 ${radius} ${radius})`}
      />
      <text x="50%" y="50%" textAnchor="middle" fill={color} strokeWidth="0px" dy=".3em" fontSize="4em">
        {progress}
      </text>
    </svg>
  );
};

  

export default function Results() {
  const navigate = useNavigate();
  const results = useSelector(state => state.resultsreducer);
  const scoreResponse = useSelector(state => state.scoreReducer);
  const dispatch = useDispatch();
    console.log(scoreResponse)
  const renderTextWithBoldNumbers = (text) => {
    return text.split(/\b/).map((word, index) => 
        /\d/.test(word) ? <strong key={index}>{word}</strong> : word
    );
  }
  const roundedScore = Math.round(scoreResponse);

  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'RESET' });
    navigate('/Instruction')
  };

  return (
    <Container>
      <ProgressContainer>
        <CircularProgress radius={100} stroke={10} targetProgress={Math.round(scoreResponse)} />
      </ProgressContainer>
      <h2 style={{ textAlign: 'center', color: '#6C63FF', marginTop: '15px' }}>Stance Score</h2>
      <Text>{renderTextWithBoldNumbers(results)}</Text>
      <ButtonStyled onClick={handleBackClick}>Try again</ButtonStyled>
    </Container>
  );
}

