import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateY(0);
  overflow-x: hidden;
  overflow-y: auto;
`;



const HomeButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #6C63FF;
  border: none;
  cursor: pointer;
`;

const Circle = styled.div`
  background-color: #6C63FF;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: -120px;
  right: -120px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: white;
  font-size: 20px;
  padding-left: 30px;
  padding-bottom: 55px;
  font-weight: bold;
`;

const Image = styled.img`
  width: 70%;
  margin: 90px auto;
  display: block;

  @media (min-width: 390px) and (max-width: 414px) {
    width: 80%;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    width: 90%;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  margin-top: 40px;

  @media (min-width: 390px) and (max-width: 414px) {
    font-size: 1.5em;
    margin-top: 20px;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    font-size: 1.8em;
    margin-top: 15px;
  }
`;

const Subtitle = styled.p`
  font-weight: lighter;
  margin-bottom: 40px;

  @media (min-width: 390px) and (max-width: 414px) {
    font-size: 1.2em;
    margin-top: 20px
  }

  @media (min-width: 415px) and (max-width: 896px) {
    font-size: 1.3em;
    margin-bottom: 15px;
  }
`;

const NextButton = styled.button`
  background-color: #6C63FF;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: auto;

  @media (min-width: 390px) and (max-width: 414px) {
    padding: 18px 35px;
    margin-top: 100px
  }

  @media (min-width: 415px) and (max-width: 896px) {
    padding: 20px 40px;
  }
`;



export default function Instruction() {
    const navigate = useNavigate();
    

  return (
    <Container>
      <HomeButton onClick={() => navigate('/')}>
      <Circle>Home</Circle>
      </HomeButton>
      <Image src="https://golfgolfappassets.s3.amazonaws.com/undraw_golf_neir.svg" alt="Golf" />
      <Title>How it works</Title>
      <Subtitle>Upload a photo of yourself addressing the ball like the above photo</Subtitle>
      <NextButton onClick={() => navigate('/Upload')}>Next</NextButton>
    </Container>
  );
}
