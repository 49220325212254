import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';


const UploadButton = styled.button`
  background-color: #6c63ff;
  color: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border: none;
  cursor: pointer;
  margin-top: auto;
  margin: auto;
`;

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateY(0);
  overflow-x: hidden;
  overflow-y: auto;
`;

const HomeButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #6c63ff;
  border: none;
  cursor: pointer;
`;

const Circle = styled.div`
  background-color: #6c63ff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  top: -120px;
  right: -120px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: white;
  font-size: 20px;
  padding-left: 30px;
  padding-bottom: 55px;
  font-weight: bold;
`;

const Image = styled.img`
  width: 70%;
  margin: 90px auto;
  display: block;

  @media (min-width: 390px) and (max-width: 414px) {
    width: 80%;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    width: 90%;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.7em;

  @media (min-width: 390px) and (max-width: 414px) {
    font-size: 1.5em;
    margin-top: 20px;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    font-size: 1.8em;
    margin-top: 15px;
  }
`;

const Subtitle = styled.p`
  font-weight: lighter;
  margin-bottom: 55px;

  @media (min-width: 390px) and (max-width: 414px) {
    font-size: 1.2em;
    margin-top: 20px;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    font-size: 1.3em;
    margin-bottom: 15px;
  }
`;

const NextButton = styled.button`
  background-color: #6c63ff;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: auto;

  @media (min-width: 390px) and (max-width: 414px) {
    padding: 18px 35px;
    margin-top: 100px;
  }

  @media (min-width: 415px) and (max-width: 896px) {
    padding: 20px 40px;
  }
`;

export default function Upload() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
  
    const { getRootProps, getInputProps } = useDropzone({
      multiple: false,
      onDrop: (acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          setImage(file);
          setFiles([file.name]);
        }
  
        if (fileRejections.length > 0) {
          console.error('Files rejected:', fileRejections);
        }
      },
    });
  
    const handleFileUpload = () => {
      inputRef.current.click();
    };
  
    useEffect(() => {
      if (files.length > 0) {
        onSubmit();
        navigate('/Loading');
      }
    }, [files]);
  
    const testApi = () => {
        const formData = new FormData();
        if (files.length > 0) {
          const file = image;
          console.log("Sending file:", file.name, "Size:", file.size);
          formData.append("image", image, "notimage.jpeg");
        }
    
        const apiUrl =
          "https://3y53ngr856.execute-api.us-east-1.amazonaws.com/glfglfdev/images";
        const testApi = "http://localhost:3000/images";
        fetch(apiUrl, {
          method: "POST",
          body: formData, // No need to manually set 'Content-Type' header
        })
          .then((response) => response.json())
          .then((data) => {
            
            dispatch({
              type: "SET_RESULTS",
              payload: data.externalApiResponse.combined_comment,
            });
            dispatch({
              type: "SET_SCORE",
              payload: data.externalApiResponse.average_score,
            });
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false);
          });
    };
  
    const onSubmit = async (event) => {
      setLoading(true);
      testApi();
    };
  
    return (
      <Container>
        <HomeButton onClick={() => navigate('/')}>
          <Circle>Home</Circle>
        </HomeButton>
        <Image src="https://golfgolfappassets.s3.amazonaws.com/Upload.svg" alt="Golf" />
        <Title>Upload your photo</Title>
        <Subtitle>Upload or take a photo of your self addressing the ball</Subtitle>
        <UploadButton onClick={handleFileUpload}>
          <span>📤</span>
        </UploadButton>
        <div {...getRootProps()} style={{ display: 'none' }}>
          <input {...getInputProps()} ref={inputRef} />
        </div>
      </Container>
    );
  }