import React from 'react';
import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;

export const ModalContainer = styled.div`
  max-width: 600px;
  max-height: 400px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid;
  padding: 10px;
  overflow: auto;
  z-index: 2; // Increased from 9999 to 3, or you can use a higher value as per your need
`;


export const CloseButton = styled.button`
  position: fixed;
  top: 8px;
  right: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Content = styled.div`
  text-align: left;
`;


