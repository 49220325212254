export default function AboutMe() {
    return (
      <section id="AboutMe" className="about--section">
        <div className="about--section--img" style={{alignItems: 'center'}}>
          <img src="https://golfgolfappassets.s3.amazonaws.com/Tripod+Animation+v2+2.mov" alt="About Me" style={{width: '70%', marginLeft: 60}}/>
        </div>
        <div className="hero--section--content--box about--section--box">
          <div className="hero--section--content">
            <p className="section--title">About</p>
            <h1 className="skills-section--heading">Birdie?</h1>
            <p className="hero--section-description">
            Birdie makes golf learning fun for beginners. Each week Birdie subscribers will have a lesson personalized to their game based on analysis by our real-life coaches and AI models. Birdie users will also have fun AI games to play that will help their real-life golf game.
            </p>
          </div>
        </div>
      </section>
    );
  }