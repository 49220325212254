const ML = "https://golfgolfbackgroundvideobucket.s3.amazonaws.com/Golf.svg"

export const homeObjOne = {
    id: 'about',
    $lightbg: false,
    $lighttext: true,
    lightTextDesc: true,
    topLine: 'Swing Solutions',
    headline: 'Change your game up with GolfGolf',
    description: "Bridge the gap between your swing and the pros' with our advanced AI technology. We curate custom reports and in-depth analysis of your golf swing. Sign up for the waitlist now or email us for inquiries.",
    buttonLabel: 'Get Started',
    $imgstart: false,
    alt: 'ML',
    $img: ML,
    $dark: true,
    $primary: true,
    $darktext: false,
    $path: '/Instruction'
}

export const homeObjTwo = {
    id: 'discover',
    $lightbg: true,
    $lighttext: false,
    lightTextDesc: false,
    topLine: 'Swing Solutions',
    headline: 'Change your game up with Ai',
    description: 'Unlock the power of our proprietary machine learning algorithm, meticulously trained to elevate your golf swing. Harnessing cutting-edge technology, we delve deep into the intricate facets that compose a flawless swing.',
    buttonLabel: 'Get Started',
    $imgstart: true,
    alt: 'Image Choice',
    $img: null,
    $dark: false,
    $primary: false,
    $darktext: true,
    $path: 'signup'
}

export const homeObjThree = {
    id: 'signup',
    $lightbg: true,
    $lighttext: false,
    lightTextDesc: false,
    topLine: 'Top Line',
    headline: 'The Head Line',
    description: 'A Whole bunch of body text that needs to tell the user about what were doing',
    buttonLabel: 'Start Now',
    $imgstart: false,
    alt: 'Golf',
    $img: null,
    $dark: false,
    $primary: false,
    $darktext: true,
    $path: '/contact'
}